import { Link } from "react-router-dom";
import AboutImg from "../assets/images/info.png";

export const Info = () => {
    return (
        <div className="bg-[#fff]">
            <div className="mx-auto w-full   p-4  py-0 flex flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">

                <div className="md:flex md:w-1/2">
                    <img src={AboutImg} alt="Hero" className="lg:w-[80%] mr-auto" />
                </div>

                <div className="lg:w-1/2 pt-10">
                    <h2 className="text-2xl md:text-2xl font-semibold text-[#292D32] text-center lg:text-left lg:w-[60%]">
                        Are you ready to take your child’s mathematics lessons to the next level?
                    </h2>

                    <p className="my-12 text-sm lg:w-[80%] text-[#292D32] text-center lg:text-left">
                        Click on the button below to enroll today.                    </p>
                    <div className="my-12 flex items-center justify-center lg:justify-start gap-5">
                        <a className="bg-[#7A61B9] rounded-[6px] px-8 py-2.5 text-white text-sm" href="mailto:contact@xmathacademy.com?subject=Enroll to X-Math Academy">Get Started</a>

                        {/* <Link to="/" className="bg-[#7A61B9] rounded-[6px] px-8 py-3 text-white text-sm">Enroll Now</Link> */}

                    </div>

                </div>



                <div>

                </div>
            </div>
        </div>
    )
}