import { Link } from "react-router-dom";
import HeroImg from "../assets/images/hero.png";

export const Hero = () => {
    return (
        <nav className="bg-[#FDFAF3] md:h-[100svh] relative top-[70px] pt-10 lg:pt-0 md:top-[80px] lg:top-[40px]" id="home">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-3 flex flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                <div className="lg:w-1/2">
                    <h2 className="text-4xl md:text-6xl font-semibold text-[#292D32] text-center lg:text-left">
                        It’s Now Easier to <br /> Study Maths  Online
                    </h2>

                    <p className="my-6 text-sm lg:w-[80%] text-[#292D32] text-center lg:text-left">
                        Welcome to X Math Academy, where we believe that every student has the potential to excel in mathematics. Our mission is to provide high-quality math education and comprehensive test prep services.
                    </p>

                    <div className="my-12 flex items-center justify-center lg:justify-start gap-5">
                        <a className="bg-[#7A61B9] rounded-[6px] px-8 py-2.5 text-white text-sm" href="mailto:contact@xmathacademy.com">Get Started</a>

                        <a href="#approach" className="flex gap-2 items-center rounded-[6px] px-8 py-2.5 text-[#292D32] text-sm">Learn More <span className="bg-[#7A61B9] w-6 h-6 block flex items-center justify-center rounded-full"><svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 24 24"><path fill="#f3f3f3" d="m14 18l-1.4-1.45L16.15 13H4v-2h12.15L12.6 7.45L14 6l6 6l-6 6Z" /></svg></span> </a>

                    </div>
                    <div className="my-12 flex items-center justify-center lg:justify-start gap-5">
                        <div className="text-center">
                            <h5 className="text-md font-semibold">1000+</h5>
                            <p className="text-xs">Registered students</p>
                        </div>
                        <div className="text-center">
                            <h5 className="text-md font-semibold">500+</h5>
                            <p className="text-xs">Tutored students</p>
                        </div>
                        <div className="text-center">
                            <h5 className="text-md font-semibold">300+</h5>
                            <p className="text-xs">Online sessons</p>
                        </div>

                    </div>
                </div>

                <div className="md:w-1/2">
                    <img src={HeroImg} alt="Hero" className="lg:w-[80%] ml-auto" />
                </div>

                <div>

                </div>
            </div>
        </nav>
    )
}