import { Link } from "react-router-dom";
import AboutImg from "../assets/images/about.png";

export const About = () => {
    return (
        <div className="bg-[#fff] py-20" id="about">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-10 flex flex-col lg:flex-row items-center justify-between flex-shrink-0 text-[#292D32] h-full">
                <div className="lg:w-1/2">
                    <h2 className="text-2xl md:text-2xl font-semibold text-[#292D32] text-center lg:text-left">
                        About X Math Academy
                    </h2>

                    <p className="my-12 text-sm lg:w-[80%] text-[#292D32] text-center lg:text-left">
                        X Math Academy is a leading educational institution specializing in mathematics. We offer a wide range of programs designed to nurture students' mathematical abilities from elementary school to high school and beyond. Our innovative curriculum ensure that students receive the best math education.                    </p>
                    <p className="my-12 text-sm lg:w-[80%] text-[#292D32] text-center lg:text-left">
                        Discover the thrilling path to math mastery, where understanding and self-confidence intertwine with the heart of learning. Let us guide you towards a passionate journey in mathematics!
                    </p>
                    <div className="my-12 flex items-center justify-center lg:justify-start gap-5">
                    <a className="bg-[#7A61B9] rounded-[6px] px-8 py-2.5 text-white text-sm" href="mailto:contact@xmathacademy.com">Get Started</a>

                        {/* <Link to="/" className="bg-[#7A61B9] rounded-[6px] px-8 py-3 text-white text-sm">Enroll Now</Link> */}

                    </div>
                
                </div>

                <div className="md:w-1/2">
                    <img src={AboutImg} alt="Hero" className="lg:w-[90%] ml-auto" />
                </div>

                <div>

                </div>
            </div>
        </div>
    )
}