import { Link } from "react-router-dom";
import AboutImg from "../assets/images/mission.png";

export const Approach = () => {
    return (
        <div className="bg-[#FDFAF3] lg:py-20 min-h-screen" id="approach" style={{ backgroundImage: 'url(https://cdn.firstcry.com/education/2022/10/05171536/An-Ideal-Teacher-Essay.jpg)', backgroundBlendMode: 'overlay', backgroundColor: 'rgba(0, 0, 0, 0.70)' }}>
            <div className="mx-auto w-full overflow-hidden mx-auto w-full max-w-screen-xl  p-4 py-10  text-[#292D32] h-full">

                <div className="lg:w-1/2 mx-auto text-center lg:pt-10">
                    <h2 className="text-2xl md:text-2xl font-semibold text-[#fff] text-center ">
                        Our Teaching Approach
                    </h2>

                    <p className="my-4 text-sm  text-[#fff] text-center ">
                        Here is what our teaching approach looks like                    </p>
                    <div className="my-12 flex items-center justify-center  gap-5">
                        <a className="bg-[#7A61B9] rounded-[6px] px-8 py-2.5 text-white text-sm" href="mailto:contact@xmathacademy.com?subject=Enroll to X-Math Academy">Get Started</a>

                        {/* <Link to="/" className="bg-[#7A61B9] rounded-[6px] px-8 py-3 text-white text-sm">Enroll Now</Link> */}

                    </div>

                </div>

                <div className="flex flex-col md:flex-row items-center justify-center gap-8">


                    <div className="p-6 bg-white rounded-md md:w-1/3">
                        <h2 className="text-md  font-semibold text-[#292D32] text-center ">
                            Curriculum
                        </h2>

                        <p className="my-8 text-sm text-center">
                            Our curriculum is carefully designed to align with educational standards and challenge students at every level. We ensure that our materials are engaging and promote a deep understanding of mathematical concepts.
                        </p>

                    </div>
                    <div className="p-6 bg-white rounded-md md:w-1/3">
                        <h2 className="text-md  font-semibold text-[#292D32] text-center ">
                            Instructional Methods
                        </h2>

                        <p className="my-8 text-sm text-center">
                            We employ a variety of instructional methods, including hands-on activities, group discussions, and visual aids, to cater to different learning styles. Our teachers create dynamic and interactive math lessons to enhance students' understanding and retention.                        </p>

                    </div>
                    <div className="p-6 bg-white rounded-md md:w-1/3">
                        <h2 className="text-md  font-semibold text-[#292D32] text-center ">
                            Instructional Methods
                        </h2>

                        <p className="my-8 text-sm text-center">
                            We believe in continuous assessment and provide regular feedback to help students track their progress and identify areas for improvement. Our teachers work closely with students to provide personalized guidance and support.                        </p>

                    </div>

                </div>



                <div>

                </div>
            </div>
        </div>
    )
}