import Logo from "../assets/images/logo.svg";

export const Footer = () => {
    return (

        <footer className="bg-[#FDFAF3]">
            <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-2">
                    <div
                        className="border-b border-gray-100 py-8 lg:order-last lg:border-b-0  lg:py-16 lg:ps-16"
                    >
                        <div className="block text-teal-600 lg:hidden w-20">
                            <img src={Logo} alt="Footer" />
                        </div>

                        <div className="mt-8 space-y-4 lg:mt-0">
                            <span className="hidden h-1 w-10 rounded bg-[#FDFAF3] lg:block"></span>

                            <div>
                                <h2 className="text-2xl font-medium text-gray-900">Subscribe to Newslatter</h2>

                                <p className="mt-4 max-w-lg text-sm text-gray-500">
                                    Be the first to  receive all the our articles, and valuable materials that can help your kids maths skills.
                                </p>
                            </div>

                            <form className="mt-6 w-full">
                                <label htmlFor="UserEmail" className="sr-only"> Email </label>

                                <div
                                    className="rounded-md border border-[#7A61B9] p-2 focus-within:transparent sm:flex sm:items-center sm:gap-4"
                                >
                                    <input
                                        type="email"
                                        id="UserEmail"
                                        placeholder="john@rhcp.com"
                                        className="w-full border-none bg-transparent focus:border-transparent focus:ring-transparent sm:text-sm h-full  py-3 outline-none border-none block"
                                    />

                                    <button
                                        className="mt-1 w-full rounded bg-[#7A61B9] px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-teal-600 sm:mt-0 sm:w-auto sm:shrink-0"
                                    >
                                        Subscribe
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="py-8 lg:py-16 lg:pe-16">
                        <div className="hidden text-teal-600 lg:block">
                            <div className="block text-teal-600 w-24">
                                <img src={Logo} alt="Footer" />
                            </div>
                        </div>

                        <div className="mt-8 grid grid-cols-1 gap-8 sm:grid-cols-3">
                            <div>
                                <p className="font-medium text-gray-900">Services</p>

                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75">
                                            1on1 Coaching
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-medium text-gray-900">Company</p>

                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75">
                                            About
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75">
                                           Community
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div>
                                <p className="font-medium text-gray-900">Contact Us</p>

                                <ul className="mt-6 space-y-4 text-sm">
                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75">
                                            Contact
                                        </a>
                                    </li>

                                    <li>
                                        <a href="#" className="text-gray-700 transition hover:opacity-75">
                                            FAQs
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="mt-8 border-t border-gray-100 pt-8">
                            <ul className="flex flex-wrap gap-4 text-xs">
                                <li>
                                    <a href="#" className="text-gray-500 transition hover:opacity-75">
                                        Terms & Conditions
                                    </a>
                                </li>

                                <li>
                                    <a href="#" className="text-gray-500 transition hover:opacity-75">
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul>

                            <p className="mt-8 text-xs text-gray-500">
                                &copy; 2023. X-Math Academy. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>)
}