import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.svg";

export const Header = () => {
    return (
        <nav className="bg-[#FDFAF3] py-2 bg-rd-500 fixed top-0 w-full h-max z-50">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-3 flex  items-center justify-between flex-shrink-0 text-white">
                <div>
                    <Link to={"/"}>
                        <img src={Logo} alt="" className="w-[90px]" />
                    </Link>
                </div>

                <div className="hidden lg:flex text-[#292D32] flex gap-10 items-center">
                    <a href="#home" className="text-base">Home</a>
                    <a href="#about" className="text-base">About Us</a>
                    <a href="#approach" className="text-base">Academics</a>
                </div>

                <div>
                    <div className="hidden lg:flex text-[#292D32] flex gap-10 items-center">
                        <a className="bg-[#7A61B9] rounded-[6px] px-8 py-2.5 text-white text-sm" href="mailto:contact@xmathacademy.com">Enroll</a>
                    </div>

                </div>
            </div>
        </nav>
    )
}