import { Link } from "react-router-dom";
import AboutImg from "../assets/images/mission.png";

export const Mission = () => {
    return (
        <div className="bg-[#FDFAF3] lg:py-20">
            <div className="mx-auto w-full max-h-[380px] overflow-hidden  p-4 lg:py-10 flex flex-col lg:flex-row items-start justify-between flex-shrink-0 text-[#292D32] h-full">

                <div className="hidden md:flex md:w-1/2">
                    <img src={AboutImg} alt="Hero" className="lg:w-[80%] mr-auto" />
                </div>

                <div className="lg:w-1/2 lg:pt-10">
                    <h2 className="text-2xl md:text-2xl font-semibold text-[#292D32] text-center lg:text-left">
                        Our Mission
                    </h2>

                    <p className="my-12 text-sm lg:w-[80%] text-[#292D32] text-center lg:text-left">
                        At X Math Academy, our mission is to empower students with the knowledge and skills they need to succeed in mathematics. We aim to instill a deep understanding of mathematical concepts, foster critical thinking, and cultivate a love for problem-solving. By providing a supportive and stimulating learning environment, we strive to inspire students to reach their full mathematical potential.
                    </p>
                    <div className="my-12 flex items-center justify-center lg:justify-start gap-5">
                    <a className="bg-[#7A61B9] rounded-[6px] px-8 py-2.5 text-white text-sm" href="mailto:contact@xmathacademy.com?subject=Enroll to X-Math Academy">Get Started</a>

                        {/* <Link to="/" className="bg-[#7A61B9] rounded-[6px] px-8 py-3 text-white text-sm">Enroll Now</Link> */}

                    </div>

                </div>



                <div>

                </div>
            </div>
        </div>
    )
}