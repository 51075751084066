import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"

export const Testimonials = () => {

    const testimonials = [
        {
            name: 'Seun Hannah',
            img: "/images/testimonial-1.svg",
            text: "My kids mathematics game became super awesome after i enrolled them at X-maths"
        },
        {
            name: 'Sepideh Yazdi',
            img: "/images/testimonial-2.png",
            text: "My kids mathematics game became super awesome after i enrolled them at X-maths"
        },
        {
            name: 'Jeodes John',
            img: "/images/testimonial-3.svg",
            text: "My kids mathematics game became super awesome after i enrolled them at X-maths"
        }
    ]

    const activeTestimonial = useRef(null);


    const totalSlide = testimonials.length

    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentText, setCurrenttext] = useState(testimonials[0].text)

    const handleChange = (duration, index) => {

        if (currentIndex >= totalSlide - 1) {

            setCurrentIndex(0)
            setCurrenttext(testimonials[0].text)


        }

        else {
            setCurrentIndex(currentIndex + 1)
            setCurrenttext(testimonials[currentIndex + 1].text)


        }


    }

    useEffect(() => {
        const interval = setInterval(() => {
            handleChange(10, 0)
        }, 8000);

        //Clearing the interval
        return () => clearInterval(interval);
    }, [currentIndex]);
    return (

        <div className="bg-[#FDFAF3]">

            <section className="mx-auto w-full max-w-screen-xl px-4 md:py-10 gap-2 py-10">
                <h4 className="font-semibold text-lg md:text-2xl text-center mb-10">Here’s what people are saying</h4>

                <div className="flex flex-col md:flex-row gap-10 justify-between items-center md:py-10">
                    <div className="md:w-[40%]">
                        {
                            testimonials.map((item, index) => {
                                return (

                                    <div ref={activeTestimonial} key={item.name} className={`${index === currentIndex ? 'border rounded-md border-[#BF9046] shadow-md' : ''} w-full p-6  mb-10 flex items-center gap-5 md:gap-10`}>
                                        <img src={item.img} alt="" className=" w-12 md:w-16" />
                                        <div>
                                            <h4 className="mb-1">{item.name}</h4>
                                            <p className="text-xs text-[#52525B]">{item.text}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className="hidden md:block md:w-[40%]">
                        <div className="p-10 py-15  rounded-md" style={{ boxShadow: '0px 8px 41px 0px rgba(0, 0, 0, 0.10)' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                                <g clipPath="url(#clip0_231_2214)">
                                    <path d="M32.9444 45.9723C30.8896 45.9793 28.8712 46.515 27.0833 47.5279C29.6004 41.0126 33.7752 35.2676 39.1944 30.8612C39.5045 30.6059 39.7612 30.292 39.95 29.9374C40.1387 29.5828 40.2558 29.1946 40.2945 28.7947C40.3332 28.3949 40.2927 27.9914 40.1755 27.6072C40.0582 27.2231 39.8665 26.8657 39.6111 26.5557C39.3558 26.2456 39.0418 25.9889 38.6873 25.8001C38.3327 25.6114 37.9444 25.4943 37.5446 25.4556C37.1448 25.4169 36.7413 25.4574 36.3571 25.5746C35.9729 25.6919 35.6156 25.8837 35.3056 26.139C24.1944 35.1668 19.25 47.6668 19.25 55.4168C19.2691 58.157 20.0858 60.8324 21.6001 63.1163C23.1145 65.4002 25.2611 67.1937 27.7778 68.2779C29.4066 69.0669 31.1902 69.4844 33 69.5001C34.5929 69.5775 36.185 69.3307 37.6797 68.7744C39.1744 68.2182 40.5405 67.3641 41.6953 66.2641C42.85 65.1641 43.7694 63.8411 44.3975 62.3752C45.0257 60.9093 45.3496 59.3311 45.3496 57.7362C45.3496 56.1414 45.0257 54.5632 44.3975 53.0973C43.7694 51.6314 42.85 50.3083 41.6953 49.2083C40.5405 48.1083 39.1744 47.2543 37.6797 46.6981C36.185 46.1418 34.5929 45.8949 33 45.9723H32.9444Z" fill="#BF9046" />
                                    <path d="M63.8889 45.9725C61.8337 45.977 59.8147 46.5129 58.0278 47.5281C60.5439 41.0197 64.7076 35.2765 70.1111 30.8614C70.4493 30.6177 70.7341 30.3072 70.9478 29.9493C71.1616 29.5913 71.2998 29.1934 71.354 28.7801C71.4082 28.3667 71.3773 27.9466 71.2631 27.5456C71.1489 27.1447 70.9538 26.7713 70.6899 26.4486C70.426 26.1258 70.0988 25.8605 69.7286 25.6689C69.3583 25.4773 68.9527 25.3635 68.5368 25.3345C68.1209 25.3056 67.7035 25.362 67.3102 25.5004C66.9169 25.6388 66.5561 25.8562 66.25 26.1392C55.1389 35.167 50.1944 47.667 50.1944 55.417C50.2076 58.1262 51.0004 60.7744 52.4781 63.0451C53.9558 65.3159 56.056 67.1133 58.5278 68.2225C60.1978 69.0347 62.0319 69.4528 63.8889 69.4448C65.4818 69.5222 67.0739 69.2753 68.5686 68.7191C70.0633 68.1628 71.4294 67.3088 72.5842 66.2088C73.7389 65.1088 74.6583 63.7857 75.2864 62.3198C75.9146 60.8539 76.2385 59.2757 76.2385 57.6809C76.2385 56.0861 75.9146 54.5078 75.2864 53.0419C74.6583 51.576 73.7389 50.253 72.5842 49.153C71.4294 48.053 70.0633 47.199 68.5686 46.6427C67.0739 46.0865 65.4818 45.8396 63.8889 45.917V45.9725Z" fill="#BF9046" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_231_2214">
                                        <rect width="100" height="100" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                            <p className="text-[#52525B]">{currentText}</p>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}